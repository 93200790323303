import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

function SubscribeForm() {
  const [email, setEmail] = useState("")
  const [hasSubscribed, setHasSubscribed] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        if (data.result === "error") {
          alert(data.msg)
        } else {
          setHasSubscribed(true)
        }
      })
      .catch(error => {
        alert(error)
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <form onSubmit={handleSubmit} className="">
      <h3>Get new posts sent to your inbox</h3>
      <div className="">
        <input
          style={{ marginRight: 10, cursor: "pointer" }}
          placeholder="Email address"
          name="email"
          type="text"
          onChange={handleEmailChange}
          value={email}
        />
        <button
          disabled={hasSubscribed}
          style={{ cursor: "pointer", marginRight: 10 }}
          type="submit"
        >
          Subscribe
        </button>
        {hasSubscribed && <span>Thank you!</span>}
      </div>
    </form>
  )
}
export default SubscribeForm
